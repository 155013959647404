:root {
  --blue-1: #0099D4;
  --blue-2: #3392c1;
  --blue-3: #66add0;
  --blue-4: #99c9e0;
  --blue-5: #cce4ef;
  --beige-1: #bfa994;
  --beige-2: #ccbbab;
  --beige-3: #ded4ca;
  --beige-4: #ece5df;
  --beige-5: #f9f7f6;
  --samsic-1: #24303B;
  --samsic-2: #565f65;
  --samsic-3: #80878c;
  --samsic-4: #80878c;
  --samsic-5: #d5d7d9;
}

body.login {
  background-color: var(--samsic-1);
}
body.login h1 a {
  background-image: url(/wp-content/themes/samsic/assets/images/samsic.jpg);
  border: solid 2px #FFFFFF;
}

#wp-auth-check-wrap #wp-auth-check {
  background-color: var(--samsic-1);
}

.interim-login.login h1 a {
  width: 84px;
}

#login .button-large,
#login .button-primary {
  background-color: var(--blue-1);
  border-color: var(--blue-1);
}
#login a,
#login #nav a,
#login #backtoblog a,
#login .button-secondary {
  color: #FFFFFF;
}
#login a:hover,
#login #nav a:hover,
#login #backtoblog a:hover,
#login .button-secondary:hover {
  color: #FFFFFF;
  text-decoration: underline;
}
#login #login_error,
#login .message,
#login .success {
  border-left-color: var(--blue-1);
}
#login .button.wp-hide-pw:focus,
#login input:focus {
  border-color: var(--color);
  box-shadow: 0 0 0 1px var(--color);
}